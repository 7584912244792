import styled from "styled-components";
import {FaArrowCircleLeft, FaArrowCircleRight} from 'react-icons/fa'


export const InfoContainer = styled.div`
  color: #fff;
  background: ${({lightBg}) => (lightBg ? '#f9f9f9' : 'var(--background-secondary)')};

  @media screen and (max-width: 768px){
    padding: 80px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  min-height: 100vh;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  @media screen and (max-width: 768px){
    padding-top: 0;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: ${({imgStart}) => (imgStart ? '5fr 4fr' : '5fr 5fr')};
  align-items: center;
  grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

  @media screen and (max-width: 768px){
    grid-template-areas: 'col2' 'col1';
  }
`;
export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  min-width: 100%;
  /* margin-bottom: 15px; */
  padding: 0 15px;
  grid-area: col2;
  @media screen and (max-width: 768px){
    padding: 0 5px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: var(--primary-color);
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};

  @media screen and (max-width: 480px){
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
  font-weight: 300;
  color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
  @media screen and (max-width: 768px){
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
  }
`;

export const BtnWrap = styled.div`
  display: ${({isButton}) => (isButton ? 'flex' : 'none')};;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  display: flex;
  max-width: 555px;
  height: auto;
`;

export const Img = styled.img`
  width: 100%;
  padding-right: 0;
  padding: 2px;
`

export const SwiperContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  width: 100%;
`;

export const SwiperWrapper = styled.div`
  height: 67vh;
  display: flex;
  @media screen and (max-width: 768px){
    height: 35vh;
  }
`;

export const BtnNavigation = styled.div`
  display: flex;
  gap: 10px;
`
export const LeftIcon = styled(FaArrowCircleLeft)`
  font-size: 30px;
  color: #fff;
`;
export const RightIcon = styled(FaArrowCircleRight)`
  font-size: 30px;
  color: #fff;
`;