export const companyPage = {
  id: 'services',
  lightBg: false,
  lightText: true,
  darkText: false,
  topLine: 'Services',
  headline: 'Make the best drink',
  desc: 'Made fresh on the island of Bali using the mix of local and import ingredients, we always keep our quality checked. Our goal is to give you our best drink by using the best ingridients.',
  imgStart: true,
  img: require('../../static/img1.svg'),
  alt: 'career progress',
  isButton: false,
  slidesDataImg: [
    require('../../static/company1.jpg'),
    require('../../static/company2.jpg'),
    require('../../static/company3.jpg'),
    require('../../static/company4.jpg'),
  ]
}
export const sojuPage= {
  id: 'NewProduct',
  lightBg: true,
  lightText: false,
  darkText: true,
  topLine: 'New Product',
  headline: 'Everyday Soju',
  desc: 'We present you our new product! Everyday Soju is a creation born from mix of the taste from Indonesia and Korea. As you know, we always use the best ingridients for our drinks. This product goal is to give people the fun to drink, so you can have high expectation for this new product! So remember to have fun every second, every minute, every hour, everyday! ',
  imgStart: false,
  img: require('../../static/img1.svg'),
  alt: 'career progress',
  // Button
  isButton: true,
  buttonLabel: "See Products!",
  slidesDataImg: [
    require('../../static/soju1.JPG'),
    require('../../static/soju2.JPG'),
    require('../../static/soju3.JPG'),
    require('../../static/soju4.JPG'),
    require('../../static/soju5.JPG'),
  ]
}