export const products= [
  {
    img: require('../../static/c1.png'),
    name: 'Tequila',
    desc: 'Minuman yang berasal dari Meksiko ini akan terasa nikmat dengan sedikit perasan lemon.'
  },
  {
    img: require('../../static/c2.png'),
    name: 'Whisky',
    desc: 'Minuman yang tidak pernah salah untuk diminum bersama dengan es.'
  },
  {
    img: require('../../static/c3.png'),
    name: 'Rum',
    desc: 'Minuman yang berasal dari Amerika Latin ini dapat dinikmati dengan membuat menjadi cocktail.'
  },
  {
    img: require('../../static/c4.png'),
    name: 'Vodka',
    desc: 'Minuman yang tidak pernah salah untuk dicampur dengan mixer'
  },
  {
    img: require('../../static/c5.png'),
    name: 'Gin',
    desc: "You're the gin to my tonic."
  },
  {
    img: require('../../static/s1.png'),
    name: 'Whisky',
    desc: 'Kadar Alkohol : 40%'
  },
  {
    img: require('../../static/s2.png'),
    name: 'Whisky',
    desc: 'Tersedia dalam kemasan 350ml!'
  },
  {
    img: require('../../static/s3.png'),
    name: 'Vodka',
    desc: 'Disini mau tulis apa'
  },
  {
    img: require('../../static/s4.png'),
    name: 'Vodka',
    desc: ''
  },
];

export const soju = [
  {
    img: require('../../static/e3.JPG'),
    name: 'Original',
    bg: require('../../static/bg1.webp')
  },
  {
    img: require('../../static/e1.JPG'),
    name: 'Grape',
    bg: require('../../static/bg1.webp')
  },
  {
    img: require('../../static/e2.JPG'),
    name: 'Lychee',
    bg: require('../../static/bg1.webp')
  },
  {
    img: require('../../static/e4.JPG'),
    name: 'Orange',
    bg: require('../../static/bg1.webp')
  },
]