export const Data = [
  {
    question: "Apakah bukti bahwa produk PT Dafa Bali Utama dikatakan mempunyai kualitas yang baik?",
    answer: "Semua produk PT Dafa Bali Utama telah terdaftar di BPOM dan telah melalui uji lab sesuai dengan standar kesehatan yang disyaratkan oleh pemerintah"
  },
  {
    question: "Apakah setiap outlet boleh menjual produk ini?",
    answer: "Semua outlet boleh membeli produk PT Dafa Bali Utama, namun untuk menjualnya kembali tentu saja harus memenuhi persyaratan administrasi yang ditetapkan oleh pemerintah (memiliki NPPBKC dan SIUP MB)"
  },
  {
    question: "Jika dibandingkan dengan produk impor ataupun produk lokal lain yang sudah ternama, mengapa harga produk PT Dafa Bali Utama masih tergolong tinggi?",
    answer: "Produk minuman beralkohol termasuk dalam produk yang dikendalikan peredarannya. Salah satu cara pengendaliannya adalah dengan pelekatan pita cukai sebagai bukti bahwa produk tersebut telah membayar cukai. Harga jual produk di dalamnya sudah termasuk biaya cukai. Jadi jika membahas mengenai masalah harga, maka harus dilakukan perbandingan yang setara. Artinya produk yang dibandingkan haruslah produk yang sama-sama dapat dibuktikan legalitasnya. Perbedaan harga juga dapat terjadi karena bahan baku yang kami pakai selalu yang terbaik demi menjada mutu dari produk."
  },
  {
    question: "Bagaimana cara membuktikan keaslian pita cukai yang dipakai oleh PT Dafa Bali Utama?",
    answer: "Dapat dibandingkan di pita cukainya apakah gol yang tercantum dan volumenya sesuai dengan produk yang diterima dan ada inisial DAFBALUT00 sebagai inisial pabrik kami sebagai pemesan pita cukai ini. Warna pita cukai biasanya berubah di setiap tahun. Untuk tahun 2021 warna pita cukai golongan C adalah cokelat"
  },
  {
    question: "Apakah merek Crystal Club masih diimpor ke Indonesia? Karena ada beredar produk yang diclaim produk Crystal Club yang diimpor dari Malaysia, Singapore, atau Filipina.",
    answer: "PT Dafa Bali Utama adalah pemegang merek resmi untuk Crystal Club di Indonesia. Van'wine and spirit, Bhd. di Malaysia telah memutuskan tidak memproduksi lagi merek ini agar tidak terjadi infiltrasi. Jadi jika ditemukan produk diklaim dengan nama Crystal Club selain yang diproduksi oleh PT Dafa Bali Utama, maka dapat dipastikan bahwa itu bukanlah produk resmi dan dirgaukan keasliannya baik dari segi produk maupun pita cukai yang dipakai. Untuk memastikan keaslian pita cukai tersebut, dapat diminta pengujiannya di kantor Bea Cukai setempat untuk menghindari adanya masalah hukum bagi outlet yang menjual produk dengan pita cukai palsu."
  },
  {
    question: "Apakah Merek dan varian produk PT Dafa Bali Utama hanya seperti yang tercantum di katalog?",
    answer: "Untuk saat ini produk yang tersedia adalah sesuai yang di katalog. Namun tentu saja di masa yang akan datang kami akan menyediakan varian ataupun merek lain sesuai dengan pekembangan pasar."
  },
]